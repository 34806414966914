table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 70%;
  width: 70%;
  border-collapse: collapse;
  text-align: left;
  margin: auto;
}
th {
  font-weight: normal;
  /*color: #039;*/
  border-bottom: 2px solid #6678b1;
  padding: 10px 8px;
}
td {
  /*color: #669;*/
  padding: 9px 8px;
  transition: .3s linear;
}
tr:hover td {
  color: #6699ff;
}
tr.disable{
  opacity: 0.5;
}