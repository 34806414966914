@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap');

/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif!important;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    /*overflow-wrap: anywhere;*/
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    /*font-family: 'Comfortaa', cursive!important;*/
    background: #f6f7fc;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif*/
}
html,
body{
    height: 100%;
}
#root {
    position: relative;
    min-height: 100%;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a{
    text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

@media (max-width: 430px) {
    *,
    *::before,
    *::after {
        font-size: 1rem;
    }
    .SnackbarItem-message{
        font-size: 0.7rem!important;
    }
}
