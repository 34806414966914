.rcw-conversation-container > .rcw-header {
  background-color: red;
}
.rcw-launcher{
  background-color: #212529;
}
.rcw-close-launcher{
  position: relative;
  left: 14px;
}
.rcw-open-launcher {
  padding: 0;
  position: relative;
  left: 10px;
}
.rcw-conversation-container .rcw-header {
  background-color: #212529;
  padding: 10px 0 10px;
}
.rcw-conversation-container .rcw-title {
  padding: 0;
  font-size: 18px;
}
.rcw-client .rcw-message-text,
.rcw-message-text{
  background-color: #eace49;
  padding: 8px;
  max-width: 300px;
}
.rcw-response .rcw-message-text {
  padding: 8px;
  max-width: 300px;
}
.rcw-message-text ol {
  margin: 0;
}
.rcw-input,
.rcw-input::before,
.rcw-message-text li,
.rcw-message-text code,
.rcw-message-text p{
  font-size: 14px;
}
.rcw-picker-btn{
  display: none;
}
.rcw-new-message{
  width: 100%;
}
.rcw-sender{
  align-items: center;
}
.rcw-input,
.rcw-messages-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.rcw-launcher .rcw-badge{
  background-color: #eace49;
  color: #212529;
}
.rcw-input::-webkit-scrollbar,
.rcw-messages-container::-webkit-scrollbar {
   width: 18px;
   padding: 10px;
 }
.rcw-input::-webkit-scrollbar-track,
.rcw-messages-container::-webkit-scrollbar-track {
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
   padding: 10px;
   height: 100px;
 }
.rcw-input::-webkit-scrollbar-thumb,
.rcw-messages-container::-webkit-scrollbar-thumb {
   border: 7.1px solid #FCFCFC;
   border-radius: 13px;
   background-color: #C4C4C4;
 }
.loader-container{
  padding: 8px;
}

@media (max-width: 430px) {
  .rcw-launcher{
    width: 50px!important;
    height: 50px!important;
    right: 10px!important;
    bottom: 10px!important;
    margin: 10px;
  }
 .rcw-open-launcher {
    left: 5px;
    top: -2px;
    width: 30px;
  }
 .rcw-conversation-container {
    margin: auto;
    width: 100%;
  }
 .rcw-close-button {
    background: none!important;
    top: 14px!important;
  }
}
