@import url(https://fonts.googleapis.com/css2?family=Licorice&display=swap);
/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif!important;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    /*overflow-wrap: anywhere;*/
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    /*font-family: 'Comfortaa', cursive!important;*/
    background: #f6f7fc;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif*/
}
html,
body{
    height: 100%;
}
#root {
    position: relative;
    min-height: 100%;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

a{
    text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

@media (max-width: 430px) {
    *,
    *::before,
    *::after {
        font-size: 1rem;
    }
    .SnackbarItem-message{
        font-size: 0.7rem!important;
    }
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 70%;
  width: 70%;
  border-collapse: collapse;
  text-align: left;
  margin: auto;
}
th {
  font-weight: normal;
  /*color: #039;*/
  border-bottom: 2px solid #6678b1;
  padding: 10px 8px;
}
td {
  /*color: #669;*/
  padding: 9px 8px;
  transition: .3s linear;
}
tr:hover td {
  color: #6699ff;
}
tr.disable{
  opacity: 0.5;
}
.rcw-conversation-container > .rcw-header {
  background-color: red;
}
.rcw-launcher{
  background-color: #212529;
}
.rcw-close-launcher{
  position: relative;
  left: 14px;
}
.rcw-open-launcher {
  padding: 0;
  position: relative;
  left: 10px;
}
.rcw-conversation-container .rcw-header {
  background-color: #212529;
  padding: 10px 0 10px;
}
.rcw-conversation-container .rcw-title {
  padding: 0;
  font-size: 18px;
}
.rcw-client .rcw-message-text,
.rcw-message-text{
  background-color: #eace49;
  padding: 8px;
  max-width: 300px;
}
.rcw-response .rcw-message-text {
  padding: 8px;
  max-width: 300px;
}
.rcw-message-text ol {
  margin: 0;
}
.rcw-input,
.rcw-input::before,
.rcw-message-text li,
.rcw-message-text code,
.rcw-message-text p{
  font-size: 14px;
}
.rcw-picker-btn{
  display: none;
}
.rcw-new-message{
  width: 100%;
}
.rcw-sender{
  align-items: center;
}
.rcw-input,
.rcw-messages-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.rcw-launcher .rcw-badge{
  background-color: #eace49;
  color: #212529;
}
.rcw-input::-webkit-scrollbar,
.rcw-messages-container::-webkit-scrollbar {
   width: 18px;
   padding: 10px;
 }
.rcw-input::-webkit-scrollbar-track,
.rcw-messages-container::-webkit-scrollbar-track {
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
   padding: 10px;
   height: 100px;
 }
.rcw-input::-webkit-scrollbar-thumb,
.rcw-messages-container::-webkit-scrollbar-thumb {
   border: 7.1px solid #FCFCFC;
   border-radius: 13px;
   background-color: #C4C4C4;
 }
.loader-container{
  padding: 8px;
}

@media (max-width: 430px) {
  .rcw-launcher{
    width: 50px!important;
    height: 50px!important;
    right: 10px!important;
    bottom: 10px!important;
    margin: 10px;
  }
 .rcw-open-launcher {
    left: 5px;
    top: -2px;
    width: 30px;
  }
 .rcw-conversation-container {
    margin: auto;
    width: 100%;
  }
 .rcw-close-button {
    background: none!important;
    top: 14px!important;
  }
}

